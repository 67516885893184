<template>
  <div class="other-config">
    <!-- 平台服务费配置 -->
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span>{{$t('Platform maintenance fee configuration')}}</span>
      </div>
      <el-form ref="platformMaintenanceFeeForm" :rules="rules" :model="platformMaintenanceFeeForm" inline label-position="top">
        <el-form-item :label="$t('amount')" prop="amount">
          <el-input type="number" min="0" v-model="platformMaintenanceFeeForm.amount">
            <template slot="append">₣</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('Monthly deduction time')" prop="date">
          <el-input type="text" v-model="platformMaintenanceFeeForm.date" :maxlength="2" :max="28" :placeholder="$t('The default value is the first day of each month')">
            <template slot="append">{{$t('day')}}</template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('status')" prop="swi">
          <el-radio-group v-model="platformMaintenanceFeeForm.swi">
            <el-radio border :label="0" style="margin-right:5px">{{$t('Open the configuration')}}</el-radio>
            <el-radio border :label="1">{{$t('Close the configuration')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin-top: 40px;">
          <el-button type="primary" :loading="isloading1" @click="platformMaintenanceFeeFormSubmit('platformMaintenanceFeeForm')">{{$t(isloading1?'loading':'submit')}}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 交易税配置 -->
    <el-card shadow="never" style="margin-top:15px">
      <div slot="header" class="clearfix">
        <span>{{$t('Transaction tax configuration')}}</span>
      </div>
      <el-form ref="transactionTaxForm" :rules="rules" :model="transactionTaxForm" inline label-position="top">
        <el-form-item :label="$t('businessName')+1" class="full-scale-input" prop="businessCode">
          <el-select v-model="transactionTaxForm.businessCode" multiple :placeholder="$t('Please select business')">
            <template v-for="(item,index) in businessList">
              <el-option-group v-if="item.parentId==0" :key="index" :label="$t(item.name)">
                <template v-for="(item2,index2) in businessList">
                  <el-option v-if="item2.parentId==item.id" :label="$t(item2.name)" :value="item2.businessCode" :key="index+index2.toString()"></el-option>
                </template>
              </el-option-group>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('amount')" prop="amount">
          <el-input type="number" min="0" v-model="transactionTaxForm.amount">
            <template slot="append">₣</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('There is no handling charge for transactions under',{money:transactionTaxForm.low||0})" prop="low">
          <el-input type="number" min="0" v-model="transactionTaxForm.low">
            <template slot="append">₣</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('status')" prop="swi">
          <el-radio-group v-model="transactionTaxForm.swi">
            <el-radio border :label="0" style="margin-right:5px">{{$t('Open the configuration')}}</el-radio>
            <el-radio border :label="1">{{$t('Close the configuration')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin-top: 40px;">
          <el-button type="primary" :loading="isloading2" @click="transactionTaxFormSubmit('transactionTaxForm')">{{$t(isloading2?'loading':'submit')}}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import config from "../../api/config"
import business from "../../api/business"
export default {
  name: "otherConfig",
  data () {
    return {
      platformMaintenanceFeeForm: { swi: 1 },
      isloading1: false,

      transactionTaxForm: { swi: 1 },
      businessList: [],
      isloading2: false,

      rules: {
        amount: [{ required: true, message: '', trigger: 'blur' },],
        swi: [{ required: true, message: '', trigger: 'blur' },],
        date: [{ required: true, message: '', trigger: 'blur' },],
        businessCode: [{ required: true, message: this.$t('Please select business'), trigger: 'blur' },],
      }
    }
  },
  methods: {
    getBusinessList (isloading2) {
      this.isloading2 = isloading2 || false;
      let _this = this;
      business.getList({
        success: res => {
          _this.$set(_this, "businessList", res.data.reverse());
          _this.getTransactionTaxForm(isloading2)
        }
      })
    },
    platformMaintenanceFeeFormSubmit (refName) {
      this.isloading1 = true;
      let _this = this;
      _this.$refs[refName].validate(valid => {
        if (!valid) return false;
        config.updatePlaMaiFee({
          param: _this[refName], success: function (res) {
            if (res.code == 200) _this.$message.success(_this.$t('operateSuccessfully'));
            else _this.$message.warning(_this.$t(res.msg || 'operationFailure'));
            _this.isloading1 = false;
          }
        })
      })
    },
    getplatformMaintenanceFeeForm (isloading1) {
      this.isloading1 = isloading1 || false;
      let _this = this;
      config.getPlaMaiFee({
        param: {}, success: function (res) {
          if (res.code == 200) _this.platformMaintenanceFeeForm = { amount: res.data.amount, swi: res.data.switch, date: res.data.date };
          else _this.$message.warning(_this.$t(res.msg || 'operationFailure'));
          _this.isloading1 = false;
        }
      })
    },

    transactionTaxFormSubmit (refName) {
      this.isloading2 = true;
      let _this = this;
      _this.$refs[refName].validate(valid => {
        if (!valid) return false;
        let form = {
          amount: _this[refName].amount,
          low: _this[refName].low || 0,
          swi: _this[refName].swi,
          business: _this[refName].businessCode.toString()
        }
        config.updateTransactionTax({
          param: form, success: function (res) {
            if (res.code == 200) _this.$message.success(_this.$t('operateSuccessfully'));
            else _this.$message.warning(_this.$t(res.msg || 'operationFailure'));
            _this.isloading2 = false;
          }
        })
      })
    },
    getTransactionTaxForm (isloading2) {
      this.isloading2 = isloading2 || false;
      let _this = this;
      config.getTransactionTax({
        param: {}, success: function (res) {
          if (res.code == 200)
            _this.$set(_this, "transactionTaxForm", { amount: res.data.amount, low: res.data.low, swi: res.data.switch, businessCode: res.data.business.split(',') });
          else
            _this.$message.warning(_this.$t(res.msg || 'operationFailure'));

          _this.isloading2 = false;
        }
      })
    }
  },
  mounted () {
    this.getplatformMaintenanceFeeForm(true)
    this.getBusinessList(true)
  }
}
</script>

<style lang="less" >
.other-config {
  .el-form-item {
    margin-right: 30px;
  }
  .full-scale-input {
    display: block;
    .el-select {
      width: 100%;
    }
  }
}
</style>